import Link from 'next/link';
import { Link as MuiLink } from '@mui/material';
import { WrapperWithDotIndicator } from '@/src/components/Common/WrapperWithDotIndicator';
type Props = {
  id: string;
  isWithIntegrationExceptions: boolean;
};
export const OrderNumber = ({
  id,
  isWithIntegrationExceptions
}: Props) => {
  return <WrapperWithDotIndicator isIndicatorVisible={isWithIntegrationExceptions} data-sentry-element="WrapperWithDotIndicator" data-sentry-component="OrderNumber" data-sentry-source-file="OrderNumber.tsx">
      <MuiLink component={Link} href={`/order/${id}/display`} sx={{
      width: 'fit-content',
      fontSize: '14px',
      fontWeight: 'fontWeightMedium'
    }} data-sentry-element="MuiLink" data-sentry-source-file="OrderNumber.tsx">
        {id}
      </MuiLink>
    </WrapperWithDotIndicator>;
};