'use client';

import { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { IconButton } from '@mui/material';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
type Props = {
  enabled: boolean;
  objectID: number;
  objectType: 'QUOTE' | 'BOM' | 'PO';
};
export const NotificationButton = ({
  enabled,
  objectType,
  objectID
}: Props) => {
  const [isEnabled, setIsEnabled] = useState(enabled);
  const tenantAlias = useTenantAlias();
  const {
    mutate
  } = useMutation(spaQueries.watchObject.setEnabled(objectType, objectID, tenantAlias));
  const handleClick = () => {
    const newValue = !isEnabled;
    mutate({
      enabled: newValue
    }, {
      onSuccess: () => {
        setIsEnabled(newValue);
        toast.success(`${newValue ? 'Enabled' : 'Disabled'} notifications`);
      }
    });
  };
  return <IconButton onClick={handleClick} title={isEnabled ? 'Disable notifications' : 'Enable notifications'} data-sentry-element="IconButton" data-sentry-component="NotificationButton" data-sentry-source-file="NotificationButton.tsx">
      {isEnabled ? <NotificationsOffIcon /> : <NotificationsNoneIcon />}
    </IconButton>;
};