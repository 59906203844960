'use client';

import { useQuery } from '@tanstack/react-query';
import ChatIcon from '@mui/icons-material/Chat';
import { Box, Button, IconButton, Paper, Typography } from '@mui/material';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { CurrentUserInfoResponse } from '@/shared/types/controllers/AuthControllerTypes';
import { BuyerDashboardDataResponse, SupplierDashboardDataResponse } from '@/shared/types/controllers/DashboardControllerTypes';
import CompanyImageIcon from '@/src/components/Common/CompanyImageIcon';
import UserImageIcon from '@/src/components/Common/UserImageIcon';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { NoConversationsFound } from './NoConversationsFound';
type RecentConversationsProps = {
  conversations: SupplierDashboardDataResponse['conversations'] | BuyerDashboardDataResponse['conversations'];
};
export const RecentConversations = ({
  conversations
}: RecentConversationsProps) => {
  const tenantAlias = useTenantAlias();
  const getCurrentUserInfoRequest = useQuery(spaQueries.user.getCurrentUserInfo(tenantAlias));
  const user = getCurrentUserInfoRequest.data?.user;
  if (conversations.length === 0) {
    return <NoConversationsFound />;
  }
  return <div data-sentry-component="RecentConversations" data-sentry-source-file="RecentConversations.tsx">
      <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      mb: 2
    }} data-sentry-element="Box" data-sentry-source-file="RecentConversations.tsx">
        <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="RecentConversations.tsx">Recent Conversations</Typography>
        <Button href="/conversation/list" variant="outlined" color="secondary" data-sentry-element="Button" data-sentry-source-file="RecentConversations.tsx">
          See all
        </Button>
      </Box>
      <div>
        {conversations.map(conversation => {
        const {
          icon,
          name
        } = getConversationDetails(user, conversation);
        return <Paper key={conversation.id} sx={{
          border: 'none',
          p: 2,
          mb: 1,
          display: 'flex',
          justifyContent: 'space-between'
        }}>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}>
                {icon}
                <Typography sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}>
                  {name}
                </Typography>
              </Box>
              <IconButton href={`/conversation/${conversation.id}/display`} aria-label={`Go to conversation with ${name}`} sx={{
            color: 'warning.contrastText',
            backgroundColor: 'warning.light'
          }}>
                <ChatIcon />
              </IconButton>
            </Paper>;
      })}
      </div>
    </div>;
};
const getConversationDetails = (user: CurrentUserInfoResponse['user'], conversation: RecentConversationsProps['conversations'][0]) => {
  const {
    company,
    contact
  } = conversation;
  if (user?.company.id === company.id && Boolean(contact)) {
    // User is an agent
    const userToDisplay = contact.user ?? contact;
    return {
      icon: <UserImageIcon user={userToDisplay} overrideHeight={40} overrideWidth={40} overrideFontSize={24} />,
      name: `${userToDisplay.firstName} ${userToDisplay.lastName}`
    };
  }

  // User is the contact
  return {
    icon: <CompanyImageIcon company={company} overrideHeight={40} overrideWidth={40} overrideFontSize={24} />,
    name: company.name
  };
};