import { RecentQuotesTableProps } from '../components/RecentQuotesTable/RecentQuotesTable';

export const getPartNumberToDisplay = (
  partRequests: RecentQuotesTableProps['quotes'][0]['partRequests'],
) =>
  partRequests.reduce((acc, partRequest) => {
    const separator = acc.length ? ', ' : '';
    const nextPartNumber =
      partRequest.part?.partNumber ||
      partRequest.customPartRevision?.customPart.partNumber ||
      '';

    return acc.concat(separator, nextPartNumber);
  }, '');

export const getImageSrc = (
  partRequests: RecentQuotesTableProps['quotes'][0]['partRequests'],
) =>
  partRequests.reduce((acc, partRequest) => {
    return partRequest.part?.images?.[0]?.target || acc;
  }, '');
