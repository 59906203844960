'use client';

import { Box, Typography, Button, Stack } from '@mui/material';
type RecentOrdersHeadingProps = {
  orderCount: number;
};
export const RecentOrdersHeading = ({
  orderCount
}: RecentOrdersHeadingProps) => {
  const orderListPath = '/order/list-supplier';
  return <Box sx={{
    my: {
      xs: 2,
      sm: 0
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }} data-sentry-element="Box" data-sentry-component="RecentOrdersHeading" data-sentry-source-file="RecentOrdersHeading.tsx">
      <Stack direction={{
      xs: 'column',
      sm: 'row'
    }} alignItems="baseline" spacing={{
      xs: 0,
      sm: 2
    }} data-sentry-element="Stack" data-sentry-source-file="RecentOrdersHeading.tsx">
        <Typography component="h2" variant="h4" data-sentry-element="Typography" data-sentry-source-file="RecentOrdersHeading.tsx">
          Recent Orders
        </Typography>
        <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-source-file="RecentOrdersHeading.tsx">{orderCount} Total Orders</Typography>
      </Stack>
      <Button href={orderListPath} variant="outlined" color="secondary" data-sentry-element="Button" data-sentry-source-file="RecentOrdersHeading.tsx">
        See all ({orderCount})
      </Button>
    </Box>;
};