import ChatIcon from '@mui/icons-material/Chat';
import { Box, Stack, Typography } from '@mui/material';
export const NoConversationsFound = () => <Stack direction="column" justifyContent="center" sx={{
  textAlign: 'center',
  minHeight: {
    xs: 100,
    lg: 300
  },
  gap: 1
}} data-sentry-element="Stack" data-sentry-component="NoConversationsFound" data-sentry-source-file="NoConversationsFound.tsx">
    <Box sx={{
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: 2,
    m: '0 auto',
    borderRadius: '50%',
    backgroundColor: 'warning.12p',
    color: 'warning.main'
  }} data-sentry-element="Box" data-sentry-source-file="NoConversationsFound.tsx">
      <ChatIcon data-sentry-element="ChatIcon" data-sentry-source-file="NoConversationsFound.tsx" />
    </Box>
    <Typography component="h2" variant="h4" data-sentry-element="Typography" data-sentry-source-file="NoConversationsFound.tsx">
      No Recent Messages
    </Typography>
    <Typography data-sentry-element="Typography" data-sentry-source-file="NoConversationsFound.tsx">{`You don't have any conversations`}</Typography>
  </Stack>;