import { PropsWithChildren } from 'react';
import { Paper } from '@mui/material';
export const CTABoxWrapper = ({
  children
}: PropsWithChildren) => <Paper sx={{
  border: 'none',
  p: 3,
  mb: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 1.5
}} data-sentry-element="Paper" data-sentry-component="CTABoxWrapper" data-sentry-source-file="CTABoxWrapper.tsx">
    {children}
  </Paper>;