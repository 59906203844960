'use client';

import Image from 'next/image';
import { Paper, Typography, Stack } from '@mui/material';
import OrderIcon from '../../assets/OrderIcon.svg';
export const NoOrdersFound = () => {
  return <Paper sx={{
    my: 2,
    p: 2,
    display: 'flex',
    gap: 2,
    border: 'none'
  }} data-sentry-element="Paper" data-sentry-component="NoOrdersFound" data-sentry-source-file="NoOrdersFound.tsx">
      <Stack sx={{
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      bgcolor: 'primary.12p',
      width: 55,
      height: 55,
      borderRadius: '50%'
    }} data-sentry-element="Stack" data-sentry-source-file="NoOrdersFound.tsx">
        <Image src={OrderIcon} width={26} height={26} alt="" data-sentry-element="Image" data-sentry-source-file="NoOrdersFound.tsx" />
      </Stack>
      <div>
        <Typography component="h2" sx={{
        typography: {
          xs: 'h4',
          sm: 'h3'
        }
      }} data-sentry-element="Typography" data-sentry-source-file="NoOrdersFound.tsx">
          No Recent Orders
        </Typography>
        <Typography data-sentry-element="Typography" data-sentry-source-file="NoOrdersFound.tsx">You have no orders yet</Typography>
      </div>
    </Paper>;
};