import {
  BuyerDashboardDataResponse,
  SupplierDashboardDataResponse,
} from '@/shared/types/controllers/DashboardControllerTypes';

export const isSupplierQuoteData = (
  quote:
    | BuyerDashboardDataResponse['quotes'][0]
    | SupplierDashboardDataResponse['quotes'][0],
): quote is SupplierDashboardDataResponse['quotes'][0] =>
  'buyer' in quote || 'contact' in quote;
