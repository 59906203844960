import currency from 'currency.js';
import { Box, Link as MuiLink, Paper, Typography } from '@mui/material';
import { IntegrationStatuses } from '@/shared/Constants';
import { BuyerDashboardDataResponse, SupplierDashboardDataResponse } from '@/shared/types/controllers/DashboardControllerTypes';
import { ALL_ORDER_STATUSES } from '@/src/components/Common/Constants';
import { OrderNumber } from '@/src/components/Order/OrderListPage/OrderNumber/OrderNumber';
import { StatusLabel } from '@/src/components/Order/OrderListPage/StatusLabel/StatusLabel';
import { isSupplierOrderData } from '../../utils/isSupplierOrderData';
import { RecentOrdersHeading } from './RecentOrdersHeading';
type MobileRecentOrdersTableProps = {
  orders: BuyerDashboardDataResponse['orders'] | SupplierDashboardDataResponse['orders'];
  orderCount: number;
};
export const MobileRecentOrdersTable = ({
  orders,
  orderCount
}: MobileRecentOrdersTableProps) => {
  return <>
      <RecentOrdersHeading orderCount={orderCount} data-sentry-element="RecentOrdersHeading" data-sentry-source-file="MobileRecentOrdersTable.tsx" />
      {orders.map(order => {
      const isOrderWithExceptions = isSupplierOrderData(order) && order.sentToIntegration === IntegrationStatuses.INTEGRATION_EXCEPTION;
      return <Paper key={order.id} sx={{
        p: 2,
        my: 1,
        border: 'none',
        display: 'flex',
        flexFlow: 'column',
        gap: 1
      }}>
            <OrderNumber id={String(order.id)} isWithIntegrationExceptions={isOrderWithExceptions} />
            {isSupplierOrderData(order) ? <>
                <Typography component="span" variant="bodyMedium">
                  CUSTOMER:{' '}
                  <MuiLink href={`/order/${order.id}/display`} color="text.secondary">
                    {order.shipCompanyName}
                  </MuiLink>
                </Typography>
              </> : <>
                <Typography component="span" variant="bodyMedium">
                  SUPPLIER:{' '}
                  <MuiLink href={`/order/${order.id}/display`} color="text.secondary">
                    {order.supplierCompanyName}
                  </MuiLink>
                </Typography>
              </>}
            <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2
        }}>
              <Typography variant="bodyMedium">
                TOTAL <strong>{currency(order.total).format()}</strong>
              </Typography>
              <StatusLabel statusText={isOrderWithExceptions ? ALL_ORDER_STATUSES.exception : order.statusText} isVisible={true} orderID={order.id} />
            </Box>
          </Paper>;
    })}
    </>;
};