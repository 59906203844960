import EditIcon from '@mui/icons-material/Edit';
import { Stack, IconButton } from '@mui/material';
import { NotificationButton } from '@/src/components/Common/Buttons/NotificationButton';
import { RecentQuotesTableProps } from './RecentQuotesTable';
type RecentQuotesActionBtnsProps = {
  quoteID: number;
  quoteWatchObject: RecentQuotesTableProps['quotes'][0]['watchObject'];
};
export const RecentQuotesActionBtns = ({
  quoteID,
  quoteWatchObject
}: RecentQuotesActionBtnsProps) => <Stack direction="row" spacing={1} data-sentry-element="Stack" data-sentry-component="RecentQuotesActionBtns" data-sentry-source-file="RecentQuotesActionBtns.tsx">
    <NotificationButton enabled={quoteWatchObject ? quoteWatchObject.enabled : false} objectType="QUOTE" objectID={quoteID} data-sentry-element="NotificationButton" data-sentry-source-file="RecentQuotesActionBtns.tsx" />
    <IconButton title="Edit" href={`/quote/${quoteID}/display`} data-sentry-element="IconButton" data-sentry-source-file="RecentQuotesActionBtns.tsx">
      <EditIcon data-sentry-element="EditIcon" data-sentry-source-file="RecentQuotesActionBtns.tsx" />
    </IconButton>
  </Stack>;