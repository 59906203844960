'use client';

import { styled } from '@mui/material/styles';

const StyledContainer = styled('div')`
  display: flex;
  align-items: center;

  & a {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    word-break: normal;
    color: white;
    padding: 5px 10px;
    border-radius: 100px;
    text-decoration: none;

    & :hover,
    :active {
      text-decoration: none;
      color: white;
    }
  }
`;

export { StyledContainer };
