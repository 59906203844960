import { useCallback } from 'react';
import { Button } from '@mui/material';
import { CreateNewQuoteDrawer } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/CreateNewQuoteDrawer';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
export const CreateNewQuoteButton = () => {
  const {
    setActiveDrawer
  } = useRightDrawerContext();
  const openDrawer = useCallback(() => {
    setActiveDrawer(<CreateNewQuoteDrawer />);
  }, [setActiveDrawer]);
  return <Button onClick={openDrawer} variant="contained" sx={{
    maxWidth: 225,
    width: '100%'
  }} data-sentry-element="Button" data-sentry-component="CreateNewQuoteButton" data-sentry-source-file="CreateNewQuoteButton.tsx">
      Create a quote
    </Button>;
};