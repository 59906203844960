import { Box, SxProps } from '@mui/material';
type IndicatorWrapperProps = {
  isIndicatorVisible: boolean;
  children: React.ReactNode;
  sxForIndicator?: SxProps;
};

/**
 * @deprecated use mui Badge instead
 */
export const WrapperWithDotIndicator = ({
  isIndicatorVisible,
  children,
  sxForIndicator
}: IndicatorWrapperProps) => <Box sx={{
  width: 'fit-content',
  display: 'flex',
  ...(isIndicatorVisible && {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      right: '-8px',
      top: '-4px',
      height: '10px',
      width: '10px',
      border: '1px solid white',
      borderRadius: '50%',
      backgroundColor: 'warning.dark',
      ...sxForIndicator
    }
  })
}} data-sentry-element="Box" data-sentry-component="WrapperWithDotIndicator" data-sentry-source-file="WrapperWithDotIndicator.tsx">
    {children}
  </Box>;