import Link from 'next/link';
import { ACCEPTED_STATUS_LABEL, CANCELLED_STATUS_LABEL, DECLINED_STATUS_LABEL, DELIVERED_STATUS_LABEL, SHIPPED_STATUS_LABEL, SUPPLIER_REVIEW_STATUS_LABEL, INTEGRATION_EXCEPTION_STATUS_LABEL, PARTIALLY_SHIPPED_STATUS_LABEL, UNKNOWN_STATUS_LABEL } from '@/src/components/Common/Constants';
import { StyledContainer } from './StatusLabel.style';
type StatusLabelProps = {
  statusText?: string;
  isVisible: boolean;
  orderID: number;
};
export const StatusLabel = ({
  statusText,
  isVisible,
  orderID
}: StatusLabelProps) => {
  if (!statusText) return null;
  const labelDetails = getLabelDetails(statusText);
  return isVisible ? <StyledContainer data-sentry-element="StyledContainer" data-sentry-component="StatusLabel" data-sentry-source-file="StatusLabel.tsx">
      <Link href={`/order/${orderID}/display`} passHref title={labelDetails.title} data-original-title={labelDetails.title} style={{
      backgroundColor: labelDetails.backgroundColor
    }} data-sentry-element="Link" data-sentry-source-file="StatusLabel.tsx">
        {labelDetails.statusText}
      </Link>
    </StyledContainer> : <div data-sentry-component="StatusLabel" data-sentry-source-file="StatusLabel.tsx" />;
};
const getLabelDetails = (statusText: string) => {
  switch (statusText) {
    case SUPPLIER_REVIEW_STATUS_LABEL.value:
      return {
        title: SUPPLIER_REVIEW_STATUS_LABEL.title,
        backgroundColor: SUPPLIER_REVIEW_STATUS_LABEL.color,
        statusText: SUPPLIER_REVIEW_STATUS_LABEL.text
      };
    case CANCELLED_STATUS_LABEL.value:
      return {
        title: CANCELLED_STATUS_LABEL.title,
        backgroundColor: CANCELLED_STATUS_LABEL.color,
        statusText: CANCELLED_STATUS_LABEL.text
      };
    case DECLINED_STATUS_LABEL.value:
      return {
        title: DECLINED_STATUS_LABEL.title,
        backgroundColor: DECLINED_STATUS_LABEL.color,
        statusText: DECLINED_STATUS_LABEL.text
      };
    case ACCEPTED_STATUS_LABEL.value:
      return {
        title: ACCEPTED_STATUS_LABEL.title,
        backgroundColor: ACCEPTED_STATUS_LABEL.color,
        statusText: ACCEPTED_STATUS_LABEL.text
      };
    case PARTIALLY_SHIPPED_STATUS_LABEL.value:
      return {
        title: PARTIALLY_SHIPPED_STATUS_LABEL.title,
        backgroundColor: PARTIALLY_SHIPPED_STATUS_LABEL.color,
        statusText: PARTIALLY_SHIPPED_STATUS_LABEL.text
      };
    case SHIPPED_STATUS_LABEL.value:
      return {
        title: SHIPPED_STATUS_LABEL.title,
        backgroundColor: SHIPPED_STATUS_LABEL.color,
        statusText: SHIPPED_STATUS_LABEL.text
      };
    case INTEGRATION_EXCEPTION_STATUS_LABEL.value:
      return {
        title: INTEGRATION_EXCEPTION_STATUS_LABEL.title,
        backgroundColor: INTEGRATION_EXCEPTION_STATUS_LABEL.color,
        statusText: INTEGRATION_EXCEPTION_STATUS_LABEL.text
      };
    case DELIVERED_STATUS_LABEL.value:
      return {
        title: DELIVERED_STATUS_LABEL.title,
        backgroundColor: DELIVERED_STATUS_LABEL.color,
        statusText: DELIVERED_STATUS_LABEL.text
      };
    default:
      return {
        title: UNKNOWN_STATUS_LABEL.title,
        backgroundColor: UNKNOWN_STATUS_LABEL.color,
        statusText: UNKNOWN_STATUS_LABEL.text
      };
  }
};